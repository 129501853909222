import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import pricesAxiosInstance from '@/utils/axios'
import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { getEnvVariable } from '@/helpers'

// Websockets connection
const websocketServer = `${getEnvVariable('VUE_APP_API_URL', process.env.VUE_APP_API_URL)}/priceshub`

const startConnection = context => {
  const token = sessionStorage.getItem('accessToken')
  context.state.connection = new HubConnectionBuilder()
    .withUrl(websocketServer, {
      accessTokenFactory: () => token,
    })
    .configureLogging(LogLevel.Information)
    .build()

  return context.state.connection.start().then(() => {
    console.log('Connection started')
  }).catch(error => {
    console.error('Failed to start connection:', error)
  })
}

const handleReceivePrices = (context, uniqueId) => currencyPriceListDTO => {
  if (!currencyPriceListDTO || currencyPriceListDTO.productoDivisas.length === 0) return

  const b = currencyPriceListDTO
  const exchanges = [{
    time: b.timestamp,
    expiresAt: b.basePrices.expiresAt,
    prices: [
      {
        time: 'Hoy',
        buy: b.basePrices.buyPriceTODAY,
        sell: b.basePrices.sellPriceTODAY,
        buyChecksum: b.basePrices.buyPriceTODAYChecksum,
        sellChecksum: b.basePrices.sellPriceTODAYChecksum,
      },
      {
        time: '24 Hrs',
        buy: b.basePrices.buyPrice24H,
        sell: b.basePrices.sellPrice24H,
        buyChecksum: b.basePrices.buyPrice24HChecksum,
        sellChecksum: b.basePrices.sellPrice24HChecksum,
      },
      {
        time: '48 Hrs',
        buy: b.basePrices.buyPrice48H,
        sell: b.basePrices.sellPrice48H,
        buyChecksum: b.basePrices.buyPrice48HChecksum,
        sellChecksum: b.basePrices.sellPrice48HChecksum,
      },
      {
        time: '72 Hrs',
        buy: b.basePrices.buyPrice72H,
        sell: b.basePrices.sellPrice72H,
        buyChecksum: b.basePrices.buyPrice72HChecksum,
        sellChecksum: b.basePrices.sellPrice72HChecksum,
      },
      {
        time: '96 Hrs',
        buy: b.basePrices.buyPrice96H,
        sell: b.basePrices.sellPrice96H,
        buyChecksum: b.basePrices.buyPrice96HChecksum,
        sellChecksum: b.basePrices.sellPrice94HChecksum,
      },
    ],
  }]
  const id = `${b.currencyPairCode}_${b.productoDivisas[0].intervalProductoId}_${b.productoDivisas[0].nmProducto.trim()}`

  context.commit('SET_PRICES', {
    clientId: id,
    prices: exchanges,
    time: b.timestamp,
    uniqueId: [uniqueId],
  })
}

const joinGroupAndSubscribe = (context, clientId, currencyPairCode, nmProducto, IdIntervalo, uniqueId) => {
  context.state.connection.invoke(
    'JoinGroup',
    currencyPairCode,
    nmProducto,
    IdIntervalo,
  ).then(() => {
    console.log('JoinGroup successful', clientId)
  }).catch(error => {
    console.error('Error invoking JoinGroup:', error)
  })

  context.state.connection.on('ReceivePrices', handleReceivePrices(context, uniqueId))
}

export default {
  namespaced: true,
  state: {
    connection: [],
    prices: [],
  },
  getters: {
    prices(state) {
      return state.prices
    },
    connections(state) {
      return state.connections
    },
  },
  mutations: {
    REMOVE_CONNECTION(state) {
      state.connection.connection.stop()
      state.prices = []
      state.connection = {}
    },

    STOP_CONNECTION(state) {
      state.connection.connection.stop()
    },

    RECONNECT_WS(state) {
      state.connection.connection.start()
    },

    SET_PRICES(state, { clientId, time, prices }) {
      const foundConnection = state.prices.find(
        con => con.clientId === clientId,
      )

      if (foundConnection) {
        Vue.set(foundConnection, 'prices', prices)
        Vue.set(foundConnection, 'clientId', clientId)
        Vue.set(foundConnection, 'time', time)
      }
    },

    ADD_NEW_UNIQUE_ID(state, { clientId, uniqueId }) {
      // buscar precios para agregar el uniqueId
      const index = state.prices.findIndex(con => con.clientId === clientId)
      state.prices[index].uniqueId.push(uniqueId)
    },
  },
  actions: {
    fetchPrices() {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('/get_prices_data')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPricesV2() {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('v1/pricing', {
            params: {
              NmUsuario: 'SYSADM',
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPricesData(_, {
      NmProducto,
      CurrencyPairCode,
      IdIntervalo,
      NmPortfolio,
    }) {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('/v1/pricing/get_price', {
            params: {
              NmProducto,
              CurrencyPairCode,
              IdIntervalo,
              NmPortfolio,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchPricesByCurrencyAndPortfolio(_, { currencyPairId, portfolioId }) {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('/get_prices', {
            params: {
              PortfolioId: portfolioId,
              CurrencyPairId: currencyPairId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSpotPrices(_, { currencyPairId }) {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('prices/get_spot_price_by_currencypair', {
            params: {
              CurrencyPairId: currencyPairId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchExchangePrices(
      _,
      {
        currencyPairId, intervalId, portfolioId, productBuyId, productSellId,
      },
    ) {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('/get_price', {
            params: {
              CurrencyPairId: currencyPairId,
              IntervalId: intervalId,
              PortfolioId: portfolioId,
              BuyProductId: productBuyId,
              SellProductId: productSellId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchExchanges() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/currencies')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    leaveGroup(context, {
      currencyPairCode,
      IdIntervalo,
      nmProducto,
      uniqueId,
    }) {
      const clientId = `${currencyPairCode}_${IdIntervalo}_${nmProducto}`
      const suscription = context.state.prices.find(con => con.clientId === clientId)
      const suscriptionIndex = context.state.prices.findIndex(con => con.clientId === clientId)

      if (suscription && suscription.uniqueId.length === 1) {
        context.state.connection.invoke(
          'LeaveGroup',
          currencyPairCode,
          nmProducto,
          IdIntervalo,
        ).then(() => {
          console.log('LeaveGroup', clientId)
          const existingWidgets = context.rootState.boards.widgets.find(e => e.type === 'WidgetExchange' || e.type === 'WidgetCapture')
          if (!existingWidgets) {
            context.commit('REMOVE_CONNECTION')
            context.state.connection = null
          }
        }).catch(error => {
          console.error('Error invoking LeaveGroup:', error)
        })
        context.state.prices.splice(suscriptionIndex, 1)
      } else {
        const i = context.state.prices[suscriptionIndex].uniqueId.findIndex(e => e === uniqueId)
        context.state.prices[suscriptionIndex].uniqueId.splice(i, 1)
      }
    },

    joinGroup(context, {
      currencyPairCode,
      IdIntervalo,
      nmProducto,
      uniqueId,
    }) {
      const clientId = `${currencyPairCode}_${IdIntervalo}_${nmProducto.trim()}`
      const existingSubscription = context.state.prices.find(con => con.clientId === clientId)

      const addNewSubscription = () => {
        const foundConnection = context.state.prices.find(con => con.clientId === clientId)
        if (!foundConnection) {
          const existingWidgets = context.rootState.boards.widgets.find(e => e.uniqueId === uniqueId)
          if (existingWidgets) {
            context.state.prices.push({ clientId, prices: [], uniqueId: [uniqueId] })
          }
        }
      }

      if (!existingSubscription) {
        if (!context.state.connection || context.state.connection.length === 0) {
          startConnection(context).then(() => {
            addNewSubscription()
            joinGroupAndSubscribe(context, clientId, currencyPairCode, nmProducto, IdIntervalo, uniqueId)
          })
        } else {
          addNewSubscription()
          joinGroupAndSubscribe(context, clientId, currencyPairCode, nmProducto, IdIntervalo, uniqueId)
        }
      } else {
        context.commit('ADD_NEW_UNIQUE_ID', { clientId, uniqueId })
      }
    },

    reconnectAndSuscribe(context) {
      startConnection(context).then(() => {
        context.state.prices.forEach(element => {
          const { clientId } = element
          const [currencyPairCode, IdIntervalo, nmProducto] = clientId.split('_')
          const interval = Number(IdIntervalo)
          joinGroupAndSubscribe(context, clientId, currencyPairCode, nmProducto, interval, element.uniqueId[0])
        })
      })
    },
  },
}

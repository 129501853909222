// Add widegt ID name to mount the widget when change board
export const widgetsToMount = [
  '',
]

export const setWidgetId = widget => {
  switch (widget.type) {
    case 'WidgetCompleteOperation':
      return `id-${widget.data.transactionNumber}`
    case 'WidgetOperationLimit':
      return `id-${widget.data.customerId}`
    case 'ModalSearch':
      return `id-${widget.data.parentId}`
    default:
      return widget.type
  }
}

import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPayeeSettlements(
      context,
      {
        BenefName,
        Customer,
        AccountCode,
        SettlementTypeStatusCode,
        CurrencyId,
        SettlementTypeId,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/payee-settlement-types/search', {
            params: {
              BenefName,
              Customer,
              AccountCode,
              SettlementTypeStatusCode,
              CurrencyId,
              SettlementTypeId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchPayeeSettlementType(context, { PayeeId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/payee-settlement-types/find-settlement-types', {
            params: {
              PayeeId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPayeeSettlement(context, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/payee-settlement-types/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createPayeeSettlement(context, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/payee-settlement-types/', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createPayee(context, payload) {
      const file = new FormData()
      file.append('PayeeId', payload.payeeId)
      file.append('PersonId', payload.personId)
      file.append('IsActive', payload.isActive)
      file.append('Version', payload.version)
      file.append('Person.PersonId', payload.person.personID)
      file.append('Person.RFC', payload.person.rfc)
      file.append('Person.Name', payload.person.name)
      file.append('Person.EconomicActivityTypeCode', payload.person.economicActivityTypeCode)
      file.append('Person.CountryId', payload.person.countryId)
      file.append('Person.IsLegalPerson', payload.person.isLegalPerson)
      file.append('Person.IsForeignCitizen', payload.person.isForeignCitizen)
      file.append('Person.ImageFile', payload.person.imageFile)
      file.append('Person.BanxicoSectorTypeId', payload.person.banxicoSectorTypeId)
      file.append('Person.StateId', payload.person.stateId)
      // file.append('Person.ResmercaSectorId', payload.person.resmercaSectorId)
      file.append('Person.IsActive', payload.person.isActive)
      file.append('Person.Version', payload.person.version)
      if (payload.person.isLegalPerson) {
        file.append('Person.LegalPersonFiscalInformation.LegalPersonId', payload.person.legalPersonFiscalInformation.legalPersonId)
        file.append('Person.LegalPersonFiscalInformation.PersonId', payload.person.legalPersonFiscalInformation.personId)
        file.append('Person.LegalPersonFiscalInformation.LegalPersonShortName', payload.person.legalPersonFiscalInformation.legalPersonShortName)
        file.append('Person.LegalPersonFiscalInformation.FiscalBusinessName', payload.person.legalPersonFiscalInformation.fiscalBusinessName)
        file.append('Person.LegalPersonFiscalInformation.EstablishmentDate', payload.person.legalPersonFiscalInformation.establishmentDate)
        file.append('Person.LegalPersonFiscalInformation.WebPage', payload.person.legalPersonFiscalInformation.webPage)
        file.append('Person.LegalPersonFiscalInformation.IsActive', payload.person.legalPersonFiscalInformation.isActive)
        file.append('Person.LegalPersonFiscalInformation.Version', payload.person.legalPersonFiscalInformation.version)
      } else {
        file.append('Person.NaturalPersonFiscalInformation.NaturalPersonId', payload.person.naturalPersonFiscalInformation.naturalPersonId)
        file.append('Person.NaturalPersonFiscalInformation.PersonId', payload.person.naturalPersonFiscalInformation.personId)
        file.append('Person.NaturalPersonFiscalInformation.PaternalSurname', payload.person.naturalPersonFiscalInformation.paternalSurname)
        file.append('Person.NaturalPersonFiscalInformation.MaternalSurname', payload.person.naturalPersonFiscalInformation.maternalSurname)
        file.append('Person.NaturalPersonFiscalInformation.Birthdate', payload.person.naturalPersonFiscalInformation.birthdate)
        file.append('Person.NaturalPersonFiscalInformation.BirthCountryID', payload.person.naturalPersonFiscalInformation.birthCountryID)
        file.append('Person.NaturalPersonFiscalInformation.IsActive', payload.person.naturalPersonFiscalInformation.isActive)
        file.append('Person.NaturalPersonFiscalInformation.Version', payload.person.naturalPersonFiscalInformation.version)
      }
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/payees/', file, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchPayee(context, { payeeId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/payees/${payeeId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchBeneficiaries(
      _,
      {
        CurrencyISOCode,
        SettlementTypeId,
        CustomerCode,
        IsBuying,
        BankingArea,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/payee-settlement-types/get_beneficiaries', {
            params: {
              CurrencyISOCode,
              SettlementTypeId,
              CustomerCode,
              IsBuying,
              BankingArea,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchBeneficiariesById(
      _,
      {
        Secuencia,
        SettlementType,
        CustomerCode,
        CurrencyCode,
        IsBuying,
        IsCCy,
        BankingArea,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/payee-settlement-types/get_beneficiaries_by_id', {
            params: {
              Secuencia,
              SettlementType,
              CustomerCode,
              CurrencyCode,
              IsBuying,
              IsCCy,
              BankingArea,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getInstructionsTransmission(
      _,
      {
        PortfolioCode,
        ForexTransactionId,
        IsCCy,
        BankingArea,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/payee-settlement-types/get_instructions_transmission_expenses', {
            params: {
              PortfolioCode,
              ForexTransactionId,
              IsCCy,
              BankingArea,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getFundsOptions(
      _,
      {
        currencyCode,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/payee-settlement-types/get_funds_by_currency', {
            params: {
              currencyCode,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchPayeeContracts(context, { payeeId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/payees/get_payee_contracts/${payeeId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    associatePayeeContracts(context, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/payees/associate_payee_contracts', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
